.order-checkout-base {
    background-color: #FFFFFF;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 40px;
    max-height: 700px;
    /* padding-top: 150px; */
    /* height: 500px; */
    /* max-height: calc(100px); */
    /* 
    
    z-index: 1;
    
    
    overflow-y: scroll;
    height: 500px; */
}

.order-checkout-order-summary {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    width: 550px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 6px;
    overflow-y: scroll;
}

.order-checkout-pricing {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.order-checkout-pricing-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-checkout-add-payment-method {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
}

.order-checkout-payment-method {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    position: relative;
}

.order-checkout-payment-method-details {
    display: flex;
    align-items: center;
    gap: 14px;
}

.order-checkout-payment-method-details-card {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order-checkout-payment-method-details-dots {
    display: flex;
    align-items: center;
    gap: 5px;
}

.order-checkout-payment-method-details-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #000000;
}

.order-checkout-payment-methods-dropdown {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 6px;
    top: -20px;
    left: 280px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
}

.order-checkout-payment-methods-dropdown.visible {
    display: block;
}

.order-checkout-payment-methods-dropdown-content {
    display: flex;
    flex-direction: column;
    max-height: 216px;
    overflow-y: scroll;
}

.order-checkout-payment-methods-dropdown-payment-method-details {
    display: flex;
    align-items: center;
    gap: 14px;
    transition: 100ms;
    background-color: #FFFFFF;
    padding: 10px;
}

.order-checkout-payment-methods-dropdown-payment-method-details:hover {
    filter: brightness(0.85);
}

.order-checkout-payment-method-dropdown-add-payment-method {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    padding: 10px;
    background-color: #FFFFFF;
    transition: 100ms;
}

.order-checkout-payment-method-dropdown-add-payment-method:hover {
    filter: brightness(0.85);
}