.ordering-base {
    width: 100%;
    background-color: #FFFFFF;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.ordering-pill {
    background-color: #EBEBEB;
    border: 1px solid #DADADA;
    border-radius: 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.ordering-pill-sections {
    display: flex;
    align-items: center;
}

.ordering-pill-section {
    border-radius: 50px;
    padding: 11px 30px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    transition: 100ms;
    position: relative;
}

.ordering-pill-section:hover {
    background-color: #d0d0d0;
}

.ordering-pill-section.active {
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 11px 30px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;
}

.ordering-pill-section.quantity {
    /* width: 70px; */
    cursor: pointer;
}

.ordering-pill-section.persona {
    width: 230px;
    cursor: pointer;
    white-space: nowrap;
}

.ordering-pill-persona-list-text {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    color: #000000;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ordering-pill-section.message {
    width: 300px;
    cursor: pointer;
    height: 40px;
}

.ordering-pill-submit {
    border-radius: 50px;
    padding: 15px;
    cursor: pointer;
    transition: 100ms;
}

.ordering-pill-submit:hover {
    background-color: #d0d0d0;
}

.ordering-pill-quantity-dropdown {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 32px;
    top: 70px;
    left: 0px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
}

.ordering-pill-quantity-dropdown.visible {
    display: block;
}

.ordering-pill-quantity-dropdown-content {
    padding: 10px 10px 6px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.ordering-pill-quantity-text-container {
    width: 70px;
    white-space: nowrap;
}

.ordering-pull-quantity-dropdown-text {
    width: 19px;
    text-align: center;
}

.ordering-pill-persona-dropdown {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 32px;
    top: 70px;
    left: -190px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
    width: 716px;
    height: 500px;
}

.ordering-pill-persona-dropdown.visible {
    display: block;
}

.ordering-pill-persona-characteristics-container {
    display: flex;
    padding: 10px;
    gap: 30px;
}

.ordering-pill-persona-characteristics {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.ordering-pill-persona-characteristic {
    padding: 8px 16px;
    border-radius: 32px;
    transition: 100ms;
    white-space: nowrap;
}

.ordering-pill-persona-characteristic.active {
    background-color: #DADADA;
}

.ordering-pill-persona-characteristic:hover:not(.active) {
    background-color: #EBEBEB;
}

.ordering-pill-persona-characteristic-details {
    padding: 7px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ordering-pill-message-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.ordering-pill-message-dropdown {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 32px;
    top: 70px;
    left: -70px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
    width: 500px;
}

.ordering-pill-message-dropdown.visible {
    display: block;
}

.ordering-pill-message-text-container {
    flex: 1;
    display: flex;
    align-content: center;
    gap: 2px;
    padding: 20px;
}

.ordering-pill-message-text-input-field {
    flex: 1;
    border: none;
    resize: none;
    font-size: 15px;
    font-weight: 400px;
    text-align: left;
    width: 260px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    height: 100px;
}

.ordering-pill-message-text-input-field::placeholder {
    color: #DADADA;
    font-weight: 400px;
}

.ordering-pill-message-text-input-field:focus {
    outline: none
}