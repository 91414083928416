.payment-method-form {
    width: 100%;
}

.payment-method-submit-button {
    border-radius: 6px;
    background-color: #609C5E;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;
    color: #FFFFFF;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
}