.privacy-policy {
    padding: 150px 200px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
  
.privacy-policy-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.privacy-policy-table-of-contents {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
.privacy-policy-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
.privacy-policy-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}