.requester-authenticated-navbar {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    opacity: 1;
    width: 100%;
    background-color: #FFFFFF;
    box-sizing: border-box;
    height: 75px;
}

.requester-authenticated-navbar.border {
    border-bottom: 1px solid #EBEBEB;
}

.requester-authenticated-navbar-container {
    width: 100%;
    padding: 15px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}