.navbar {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    opacity: 1;
    width: 100%;
    background-color: #FFFFFF;
    box-sizing: border-box;
    height: 75px;
}

.navbar.border {
    border-bottom: 1px solid #EBEBEB;
}

.navbar-container {
    max-width: 1250px;
    width: 100%;
    padding: 15px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Navbar Logo */

.navbar-logo-container {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    flex: 1;
}

.navbar-logo {
    max-width: 50px;
}

/* Navbar Links */

.nav-links {
    display: flex;
    justify-content: center;
    flex: 1;
    gap: 10px;
}

.nav-link-dropdown {
    position: relative;
    transition: 100ms;
    text-decoration: none;
}

.nav-link-dropdown-button {
    transition: 100ms;
    cursor: pointer;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    white-space: nowrap;
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 10px 15px;
}

.nav-link-dropdown-button:hover {
    background-color: #eeeeee;
}

.nav-link-dropdown-content-container {
    display: block;
	position: absolute;
	top: 36px;
	z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
}

.nav-link-dropdown:hover .nav-link-dropdown-content-container {
	opacity: 1;
    visibility: visible;
}

.nav-link-dropdown-content {
    margin-top: 15px;
    background-color: #FFFFFF;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
	padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.nav-link-dropdown-content-item {
    display: flex;
    align-items: center;
    gap: 23px;
    transition: 100ms;
    padding: 7px 14px;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
}

.nav-link-dropdown-content-item:hover {
    background-color: #eeeeee;
}

.nav-link-dropdown-content-item-text-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nav-link-dropdown-content-item-title-text {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    white-space: nowrap;
}

.nav-link-dropdown-content-item-detail-text {
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
}

/* Navbar Actions */

.navbar-actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    flex: 1;
}

.login-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #000000;
}

.earn-as-user-button {
    border-radius: 50px;
    padding: 10px 20px;
    background: rgb(242, 246, 241);
    border: none;
    cursor: pointer;
    transition: 100ms;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #000000;
    text-decoration: none;
}

.earn-as-user-button:hover {
    background-color: rgb(215, 233, 211);
}