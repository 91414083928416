.feature-list-section {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 70px 0px;
}

.feature-list {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.feature-list-row {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
}

.feature-list-item {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.feature-list-item-title {
    display: flex;
    align-items: center;
    gap: 15px;
}

.feature-list-item-title-bar {
    width: 2px;
    height: 18px;
    background-color: #609C5E;
}

.feature-list-item-title-bar.clear {
    width: 1px;
    height: 100%;
    background-color: transparent;
}

.feature-list-item-title-text {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    color: #000000;
}

.feature-list-item-text {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    color: #000000;
    line-height: 1.5;
}