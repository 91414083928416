.jobs-section {
    padding: 160px 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: left;
    width: 100%;
}
  
.jobs-section-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.jobs-section-header-text {
    font-size: 50px;
    font-weight: 700;
    margin: 0;
    color: #000000;
}

.jobs-section-detail-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: calc(100vh - 75px - 320px);
}

.jobs-section-detail-text {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    color: #000000;
    line-height: 28px;
}