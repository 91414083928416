.footer {
    display: flex;
    background-color: #F9F9F9;
    color: #222222;
    width: 100%;
}
  
.footer-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    padding: 48px 80px;
}
  
.footer-section {
    flex: 1;
}
  
.footer-section h6 {
    color: #333;
    margin-bottom: 20px;
}
  
.footer-section ul {
    list-style-type: none;
    padding: 0;
}
  
.footer-section ul li {
    margin-bottom: 10px;
}
  
.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
  
.footer-bottom p {
    margin: 0;
}
  
.footer-bottom-links,
.footer-bottom-icons {
    display: flex;
    flex-wrap: wrap; /* Ensures links and icons wrap on smaller screens */
    justify-content: center;
    gap: 10px;
}
  
.footer-bottom-links a,
.footer-bottom-icons a {
    color: #333;
    text-decoration: none;
}
  
.footer-bottom-icons span {
    white-space: nowrap; /* Prevents breaking the text */
}
  
.footer-bottom-section {
    display: flex;
    align-items: center;
}
  
.footer-bottom-section.legal {
    gap: 10px;
}
  
.footer-link-text {
    color: #000000;
    text-decoration: none;
}
  
.footer-link-text:hover {
    text-decoration: underline;
}
  
.footer-bottom-section.info {
    gap: 20px;
}
  
.footer-icon-container {
    display: flex;
    align-items: center;
    gap: 5px;
}
  
@media (max-width: 768px) {
    .footer-content {
        justify-content: center;
    }
  
    .footer-section {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }
  
    .footer-bottom-links a,
    .footer-bottom-icons a,
    .footer-bottom-icons span {
        font-size: 12px; /* Smaller font size for mobile */
    }
}