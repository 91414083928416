.blog-post-preview-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog-post-preview-category-date {
    display: flex;
    justify-content: space-between;
}

.blog-post-preview-metadata-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blog-post-preview-vertical-bar {
    width: 2px;
    height: 18px;
    background-color: #609C5E;
}

.blog-post-preview-category-text {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #609C5E;
}

.blog-post-preview-title-author {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.blog-post-preview-title {
    max-width: 73%;
    text-decoration: none;
}

.blog-post-preview-title-text {
    font-size: 40px;
    font-weight: 600;
    margin: 0;
    color: #000000;
}

.blog-post-preview-author {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.blog-post-preview-author-image-container {

}

.blog-post-preview-author-metadata {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: right;
}

.blog-post-preview-author-name {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #000000;
}

.blog-post-preview-author-title {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #727272;
}

.blog-post-preview-description-image {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.blog-post-preview-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 80%;
    flex: 1;
}

.blog-post-preview-description-text {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    color: #000000;
    line-height: 28px;
}

.blog-post-preview-read-more-button {
    display: flex;
    align-items: center;
    gap: 3px;
    text-decoration: none;
    transition: 100ms;
}

.blog-post-preview-read-more-button:hover {
    filter: brightness(0.85);
}

.blog-post-preview-read-more-text {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #609C5E;
}

.blog-post-preview-image-container {
    box-shadow: 0 30px 60px -12px rgba(50,50,93,0.25),0 18px 36px -18px rgba(0,0,0,0.3);
    border-radius: 8px;
    width: 540px;
}

.blog-post-preview-image {
    width: 540px;
}