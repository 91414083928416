.use-case-section {
    width: 100%;
    padding: 70px 0px 0px 0px;
    display: grid;
    grid: auto/minmax(0, 1fr);
    row-gap: 40px;
    align-items: flex-start;
}

.use-case-section-title-arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.use-case-section-title-text {
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    color: #000000;
}

.use-case-list-arrow {
    cursor: pointer;
}

.FullWidthCarousel {
    display: flex;
    width: 100vw;
    margin-left: calc(calc(calc(100vw - 0px)/2 - 1080px/2) * -1);
}

.FullWidthCarousel__track {
    scroll-snap-type: none;
    position: relative;
    display: flex;
    scroll-snap-type: mandatory;
    scroll-padding-left: calc(calc(100vw - 0px)/2 - 1080px/2);
    scroll-padding-right: calc(calc(100vw - 0px)/2 - 1080px/2);
    overflow-x: scroll;
    overscroll-behavior-x: contain;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    padding: 16px 0px 144px 0px;
}

.FullWidthCarousel__track::-webkit-scrollbar {
    background: transparent;
    width: 0px;
}

.FullWidthCarousel__spacer {
    min-width: calc(calc(100vw - 0px)/2 - 1080px/2);
    scroll-snap-align: none;
    margin: 0;
    height: 1px;
}

.UseCaseCard {
    --whiteColor: #FFFFFF;
    --buttonColor: var(--whiteColor);
    --linkColor: var(--whiteColor);
    --linkHoverColor: var(--whiteColor);
    --linkHoverOpacity: 0.6;
    --fontWeightBold: 500;
    --linkWeight: var(--fontWeightBold);
    --spaceBetweenCards: 26px;
    --windowWidth: 100vw;
    --layoutWidth: 1080px;
    --overflowMargin: calc(var(--windowWidth)/2 - var(--layoutWidth)/2);
    --cardWidth: calc(var(--windowWidth) - var(--overflowMargin) * 2 - var(--spaceBetweenCards));
    --cardHeight: auto;
    --transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
    --hoverScaleX: 1.081967213;
    --hoverScaleY: 1.081967213;
    --cardShadowXLarge: 0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3);
    --cardHoverShadow: var(--cardShadowXLarge);
    --columnPaddingNormal: 16px;
    flex-shrink: 0;
    position: relative;
    margin-right: var(--spaceBetweenCards);
    font: var(--fontWeightNormal) 15px/1.6 var(--fontFamily);
    letter-spacing: .2px;
    color: var(--whiteColor);
    width: var(--cardWidth);
    height: var(--cardHeight);
    cursor: pointer;
}

.UseCaseCard:last-of-type {
    margin-right: 0;
}

.UseCaseCard:first-of-type {
    margin-right: var(--spaceBetweenCards);
}

@media (min-width: 600px) {
    .UseCaseCard {
        --cardWidth: calc(var(--windowWidth)/2 - var(--overflowMargin) * 2/2 - var(--spaceBetweenCards));
    }
}

@media (min-width: 1200px) {
    .UseCaseCard {
        --cardWidth: 350px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .UseCaseCard {
        --transition: none;
    }
}

.UseCaseCard__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform, opacity;
}

.UseCaseCard__background:after, .UseCaseCard__background:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    content: "";
}

.UseCaseCard__background:before {
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25), 0 3px 7px -3px rgba(0, 0, 0, 0.3);
}

.UseCaseCard__background.color1:after {
    background-color: #609C5E;
}

.UseCaseCard__background.color2:after {
    background-color: #2aba28;
}

.UseCaseCard__background.color3:after {
    background-color: #437b8d;
}

.UseCaseCard__background.color4:after {
    background-color: #54b198;
}

.UseCaseCard__background.color5:after {
    background-color: #89bc3c;
}

.UseCaseCard__background--hover {
    opacity: 0;
}

.UseCaseCard__background--hover:before {
    box-shadow: var(--cardHoverShadow);
}

.UseCaseCard__background--hover:after {
    background-color: #0a4011;
}

@media (prefers-reduced-motion: reduce) {
    .UseCaseCard__background--hover:before {
        box-shadow: none;
    }
}

@media (pointer: fine) {
    .UseCaseCard:hover .UseCaseCard__background {
        opacity: 1;
    }

    @media (prefers-reduced-motion: no-preference) {
        .UseCaseCard:hover .UseCaseCard__background {
            transform: scale(var(--hoverScaleX), var(--hoverScaleY));
        }
    }
}

.UseCaseCard__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 350px;
    padding: 16px var(--columnPaddingNormal);
    box-sizing: border-box;
}

.UseCaseCard__header {
    margin-bottom: 8px;
}

.UseCaseCard__title {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.UseCaseCard__body {
    font-size: 17px;
    font-weight: 400;
    margin: 0;
    line-height: 1.5;
}

.UseCaseCard__date {
    opacity: 0.7;
}

.UseCaseCard__content .Link {
    padding: 1px 4px 3px;
    border-radius: 4px;
    background-color: rgba(9, 36, 64, .075);
    transition: background-color var(--transition);
}

.UseCaseCard:hover .UseCaseCard__content .Link {
    background-color: hsla(0, 0%, 100%, .11);
}

.UseCaseCard__footer {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end
}

.use-case-item-action {
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 50px;
    /* padding: 8px 9px 8px 15px; */
    max-width: fit-content;
    cursor: pointer;
    transition: 100ms;
}

.use-case-item-action:hover {
    filter: brightness(0.9);
}

.use-case-item-action-text {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    color: #FFFFFF;
}