.hero-section {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 80px;
    text-align: left;
    padding: 160px 0px 120px 0px;
}

.hero-announcement-text-action-section {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 70px;
}

.hero-announcement-text-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.hero-announcement-section {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 6px 10px 6px 16px;
    border-radius: 16px;
    white-space: nowrap;
    background-color: #4e6941;
    max-width: fit-content;
}

.hero-announcement-text {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    color: #FFFFFF;
}

.hero-announcement-action-section {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: 100ms;
}

.hero-announcement-action-section:hover {
    filter: brightness(0.85);
}

.hero-text-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.hero-image-section {
    flex: 1;
    display: flex;
    position: relative;
}

.hero-primary-text {
    font-size: 70px;
    font-weight: 700;
    margin: 0;
    color: #FFFFFF;
    line-height: 80px;
}

.hero-secondary-text {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    color: #FFFFFF;
    line-height: 1.5666;
}

.call-to-action-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 80px 0px;
}

.get-started-button {
    border-radius: 50px;
    padding: 10px 20px;
    background: rgb(242, 246, 241);
    border: none;
    cursor: pointer;
    transition: 100ms;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #000000;
    text-decoration: none;
    white-space: nowrap;
    width: 90px;
}

.get-started-button:hover {
    background-color: rgb(215, 233, 211);
}