.personas-list-section {
    width: 100%;
    padding: 70px 0px 0px 0px;
    display: grid;
    grid: auto/minmax(0, 1fr);
    row-gap: 40px;
    align-items: flex-start;
}

.PersonaCard {
    --whiteColor: #FFFFFF;
    --buttonColor: var(--whiteColor);
    --linkColor: var(--whiteColor);
    --linkHoverColor: var(--whiteColor);
    --linkHoverOpacity: 0.6;
    --fontWeightBold: 500;
    --linkWeight: var(--fontWeightBold);
    --spaceBetweenCards: 26px;
    --windowWidth: 100vw;
    --layoutWidth: 1080px;
    --overflowMargin: calc(var(--windowWidth)/2 - var(--layoutWidth)/2);
    --cardWidth: calc(var(--windowWidth) - var(--overflowMargin) * 2 - var(--spaceBetweenCards));
    --transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
    --hoverScaleX: 1.081967213;
    --hoverScaleY: 1.081967213;
    --cardShadowXLarge: 0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3);
    --cardHoverShadow: var(--cardShadowXLarge);
    --columnPaddingNormal: 16px;
    flex-shrink: 0;
    position: relative;
    margin-right: var(--spaceBetweenCards);
    font: var(--fontWeightNormal) 15px/1.6 var(--fontFamily);
    letter-spacing: .2px;
    color: #000000;
    width: var(--cardWidth);
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.PersonaCard:last-of-type {
    margin-right: 0;
}

.PersonaCard:first-of-type {
    margin-right: var(--spaceBetweenCards);
}

@media (min-width: 600px) {
    .PersonaCard {
        --cardWidth: calc(var(--windowWidth)/2 - var(--overflowMargin) * 2/2 - var(--spaceBetweenCards));
    }
}

@media (min-width: 1200px) {
    .PersonaCard {
        --cardWidth: 280px;
    }
}

.PersonaCard__image {
    width: 100%;
    max-width: 280px;
    height: 150px;
    /* background-color: #DADADA; */
    border-radius: 6px;
}

.PersonaCard__image.color1 {
    background: linear-gradient(45deg, #0f2027, #203a43, #2c5364);
}

.PersonaCard__image.color2 {
    background: linear-gradient(to bottom right, #ffecd2, #fcb69f);
}

.PersonaCard__image.color3 {
    background: linear-gradient(120deg, #abecd6, #fbed96);
}

.PersonaCard__image.color4 {
    background: linear-gradient(135deg, #667eea, #764ba2);
}

.PersonaCard__image.color5 {
    background: linear-gradient(to top left, #ee9ca7, #ffdde1);
}

.PersonaCard__image.color6 {
    background: linear-gradient(160deg, #2b5876, #4e4376);
}

.PersonaCard__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    box-sizing: border-box;
}

.PersonaCard__header {
    margin-bottom: 8px;
}

.PersonaCard__title {
    font-size: 17px;
    font-weight: 600;
    margin: 0;
}

.PersonaCard__text {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    line-height: 1.5;
}

.PersonaCard__boldText {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    line-height: 1.5;
}

.PersonaCard__button {
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 50px;
    border: 1px solid #DADADA;
    padding: 9px 10px 9px 16px;
    box-shadow: 0px 2px 4px 0px rgba(77, 77, 77, 0.5);
    cursor: pointer;
    transition: 100ms;
    align-self: flex-start
}

.PersonaCard__button:hover {
    box-shadow: 0px 2px 7px 0px rgba(77, 77, 77, 0.5);
}

.PersonaCard__submit {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #609C5E;
    display: flex;
    align-items: center;
    justify-content: center;
}