@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: "Inter", "Helvetica Neue", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea, button {
  margin: 0;
  font-family: "Inter", "Helvetica Neue", "Arial", sans-serif;
  font-size: 12px;
}

/* Text */

.black-header-1-text {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
  color: #000000;
}

.black-header-2-text {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  color: #000000;
}

.black-header-3-text {
  font-size: 25px;
  font-weight: 600;
  margin: 0;
  color: #000000;
}

.black-header-4-text {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #000000;
}

.black-header-5-text {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  color: #000000;
}

.black-header-6-text {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  color: #000000;
}

.black-regular-4-text {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  color: #000000;
}

.black-regular-5-text {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: #000000;
  line-height: 1.4;
}

.black-regular-6-text {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  color: #000000;
}

.gray-regular-4-text {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #5C5C5C;
}

.gray-regular-5-text {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: #5C5C5C;
}

.gray-regular-6-text {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #5C5C5C;
}

.light-gray-header-6-text {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  color: #A6A6A6;
}

.light-gray-regular-5-text {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: #A6A6A6;
}

.green-header-1-text {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
  color: #609C5E;
}

.green-header-2-text {
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  color: #609C5E;
}

.green-header-4-text {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #609C5E;
}

.green-header-5-text {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  color: #609C5E;
}

.green-header-6-text {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  color: #609C5E;
  white-space: nowrap;
}

.green-regular-5-text {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  color: #609C5E;
  cursor: pointer;
}

.green-regular-6-text {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  color: #609C5E;
  cursor: pointer;
}

.red-regular-5-text {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: rgb(193, 53, 21);
}

.white-header-1-text {
  font-size: 50px;
  font-weight: 600;
  margin: 0;
  color: #FFFFFF;
}

.white-header-5-text {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  color: #FFFFFF;
}

.white-header-6-text {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  color: #FFFFFF;
}

.white-regular-4-text {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: #FFFFFF;
}

.white-regular-5-text {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: #FFFFFF;
}

/* Layout */

.page-base {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.page-base.gray {
  background-color: #F9F9F9;
}

.page-base.white {
  background-color: #FFFFFF;
}

.content-background {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-background.green-gradient {
  background-image: linear-gradient(to bottom right, #71B06F, #5F875D);
}

.content-background.light-green {
  background-color: #F7FCF9;
}

.content-background.white {
  background-color: #FFFFFF;
}

.content-section {
  max-width: 1250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  z-index: 1;
}

/* App Store Link */

.app-store-link-container {
  width: 100%;
  max-width: 200px;
}

/* Landing Page Header */

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 80px;
  text-align: center;
  padding: 200px 0px 150px 0px;
}

.header-section-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
  width: 100%;
}

.header-section-left-detail-text-container {

}

.header-section-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  text-align: center;
}

.header-section-2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.header-section-wrapped-text {
  max-width: 500px;
  line-height: 1.5;
}

/* Button */

.localization-button {
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
  transition: 100ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.localization-button:hover {
  background-color: #F3F3F3;
}

.user-button {
  background-color: #F1F1F1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-left: 10px;
  cursor: pointer;
  transition: 200ms;
}

.user-button:hover {
  background-color: #e2e2e2;
}

.button-submit {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #609C5E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-button {
  border: 1px solid #DADADA;
  border-radius: 50px;
  padding: 5px 7px 5px 14px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  box-shadow: 0px 2px 4px 0px rgba(77, 77, 77, 0.5);
  cursor: pointer;
  transition: 100ms;
}

.small-button:hover {
  box-shadow: 0px 2px 7px 0px rgba(77, 77, 77, 0.5);
}

.small-button-submit {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #609C5E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.large-button-submit {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #609C5E;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.full-width-button {
  border-radius: 12px;
  padding: 14px 20px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  color: #FFFFFF;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s ease;
}

.full-width-button.disabled {
  cursor: auto;
  padding: 8px 0px;
}

.gray-full-width-button {
  border-radius: 12px;
  padding: 14px 20px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: #DADADA;
  color: #FFFFFF;
  font-weight: 500;
}

/* Text Input Box */

.text-input-box {
  padding: 12px 14px;
  background-color: #F3F3F3;
	border: none;
	border: 1px solid #DADADA;
	border-radius: 12px;
	font-size: 15px;
	text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.text-input-box:focus {
	outline: none;
	border: 1px solid #609C5E;
}

.red-text-input-box {
  padding: 12px 14px;
	border: none;
  background-color: rgb(254, 248, 246);
	border: 1px solid rgb(193, 53, 21);
	border-radius: 6px;
	font-size: 15px;
	text-align: left;
}

.text-input-box-circular-border {
  border-radius: 32px;
  border: 1px solid #DADADA;
  padding: 7px 15px;
  font-size: 15px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.text-input-box-circular-border:focus {
  outline: none;
}

.text-input-box-no-border {
  border: none;
  font-size: 15px;
  font-weight: 400px;
  text-align: left;
  min-width: 15px;
  max-width: 38px;
  width: auto;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.text-input-box-no-border::placeholder {
  color: #000000;
  font-weight: 400px;
}

.text-input-box-no-border:focus {
  outline: none
}

.text-input-box-no-border-gray-placeholder {
  border: none;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.text-input-box-no-border-gray-placeholder:focus {
  outline: none
}

/* User Profile Dropdown */

.user-profile-dropdown {
  position: relative;
}

.user-profile-dropdown-content {
	position: absolute;
  right: 0;
  margin-top: 15px;
	background-color: #FFFFFF;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	z-index: 10;
	border-radius: 5px;
	padding: 7px;
  width: 200px;
}

.user-profile-dropdown-item {
	padding: 10px 10px;
	text-align: left;
	text-decoration: none;
	display: block;
	cursor: pointer;
	border-radius: 5px;
	transition: 100ms;
}

.user-profile-dropdown-item:hover {
	background-color: #F3F3F3;
}

.user-profile-dropdown-divider {
  border: 0.5px solid #DADADA;
}

/* Modal */

.gray-modal-background {
	background: rgba(35, 35, 35, 0.6);
	z-index: 11;
	position: fixed;
  top: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.gray-modal-background-centered {
	background: rgba(35, 35, 35, 0.6);
	z-index: 2;
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-container {
	background-color: #FFFFFF;
	border-radius: 12px;
	min-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  position: relative;
}

.modal-header-container {
	display: flex;
	align-items: center;
	gap: 15px;
  height: 64px;
  width: 100%;
  padding: 0px 24px;
  border-bottom: 0.5px solid #DADADA;
  box-sizing: border-box;
}

.modal-header-title {
  flex: 1;
  text-align: center;
}

.modal-content-container {
	padding: 24px;
	min-width: 400px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.modal-close {
  position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.modal-secondary-action-container {
	margin-top: 40px;
}

.loading-circle {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #609C5E; /* Change the color as needed */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* AuthModal */

.auth-modal-base {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.auth-modal-error-message {
  display: flex;
  align-items: center;
  gap: 7px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.form-level {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
}

.form-text-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.form-selectable-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 13px;
  border: 1px solid #DADADA;
  background-color: #F3F3F3;
  border-radius: 12px;
  max-width: 200px;
  cursor: pointer;
}

.form-selectable-field.active {
  background-color: rgba(126, 153, 125, 0.2);
  border: 1px solid #609C5E;
}

/* Sign In */

.sign-in-secondary-action {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 40px;
}

/* Persona Explorer */

.persona-explorer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
}

/* Persona Explorer Filters */

.persona-explorer-filters {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.persona-explorer-product-filters {
  display: flex;
  align-items: center;
  gap: 30px;
}

.persona-explorer-product-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.persona-explorer-product-filter.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 10px;
  cursor: pointer;
}

.persona-explorer-filters-divider {
  width: 2px;
  height: 40px;
  background-color: #DADADA;
}

.persona-explorer-characteristic-arrow-section {
  position: absolute;
  width: 60px;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s ease-in-out, background-color 0.1s ease-in-out; /* Added background-color transition */
}

.left-arrow {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white 10px);
}

.right-arrow {
  right: 35px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 10px);
  justify-content: flex-end;
}

.persona-explorer-characteristic-arrow-section.show {
  opacity: 1;
}

.persona-explorer-characteristic-arrow {
  border: 1px solid #DADADA;
  border-radius: 50px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 100ms;
}

.persona-explorer-characteristic-arrow:hover {
  background-color: #F0F0F0;
}

.persona-explorer-characteristic-filters {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-x: auto;
  white-space: nowrap;
}

.persona-explorer-characteristic-filters::-webkit-scrollbar {
  display: none;
}

.persona-explorer-characteristic-filter {
  border: 1px solid #DADADA;
  border-radius: 50px;
  padding: 7px 10px 7px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: 100ms;
}

.persona-explorer-characteristic-filter:hover {
  background-color: #F0F0F0;
}

/* Persona Explorer Row */

.persona-explorer-row {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.persona-explorer-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.persona-explorer-item-header-arrows {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.persona-explorer-item-header-arrow {
  border: 1px solid #DADADA;
  border-radius: 50px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 100ms;
  cursor: pointer;
}

.persona-explorer-item-header-arrow:hover {
  background-color: #F0F0F0;
}

/* Persona Explorer Row Carousel */

.persona-explorer-row-carousel {
  display: flex;
  gap: 30px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;
}

.persona-explorer-row-carousel::-webkit-scrollbar {
  display: none;
}

.persona-explorer-row-carousel-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
  min-width: 250px;
  box-sizing: border-box;
  padding: 0px 3px 5px 3px;
  cursor: pointer;
}

.first-five {
  flex: 0 0 calc((100% - 4 * 30px) / 5); /* Distribute the width equally among the first five elements */
}

.persona-explorer-row-carousel-item-image {
  width: 100%;
  height: 120px;
  border-radius: 5px;
  background-color: #609C5E;
}

.persona-explorer-row-carousel-item-metadata {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Characteristic Filters */

.characteristic-filter-dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 10;
  margin-top: 10px;
  /* transform: translateX(-40px); */
  transition: 0.2s ease-in-out;
  opacity: 0;
  overflow-y: scroll;
  max-height: 250px;
}

.characteristic-filter-dropdown-content.visible {
  display: block;
  opacity: 1;
}

.characteristic-filter-dropdown-content::before {
  content: '';
  position: absolute;
  top: -10px; /* Position the triangle above the content */
  left: 50%; /* Center the triangle horizontally */
  transform: translateX(-50%); /* Adjust to perfectly center the triangle */
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

/* OccupationCharacteristicFilterDropdown */

.occupation-dropdown-search {
  border-bottom: 1px solid #DADADA;
  padding: 0px 10px;
  min-width: 250px;
  display: flex;
  align-items: center;
}

.occupation-dropdown-item {
  padding: 7px 10px;
  cursor: pointer;
  transition: 100ms;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.occupation-dropdown-item:hover {
  background-color: #F0F0F0;
}

/* Create Order */

.opportunity-characteristic-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.opportunity-characteristic-search-bar {
  border-radius: 32px;
  border: 1px solid #DADADA;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  gap: 8px;
}

.opportunity-characteristic-options {
  display: flex;
  flex-direction: column;
  max-height: 383px;
  overflow-y: scroll;
}

.opportunity-characteristic-option {
  padding: 7px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 100ms;
  border-radius: 32px;
  cursor: pointer;
}

.opportunity-characteristic-option:hover {
  background-color: #EBEBEB;
}

.occupation-characteristic-unselected-space {
  width: 15px;
}

/* User Marketing */

.user-marketing-header {
  display: flex;
  align-items: center;
  gap: 40px;
  /* width: 80%; */
  padding: 200px 0px 150px 0px;
}

.user-marketing-header-left {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1;
}

.user-marketing-header-text {
  /* display: inline; */
}

.user-marketing-register {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F3F3F3;
  border: 1px solid #DADADA;
  border-radius: 50px;
  padding: 10px 12px 10px 16px;
  transition: 200ms;
  cursor: pointer;
}

.user-marketing-register:hover {
  box-shadow: 0 1px 20px rgba(0,0,0,0.08),0 4px 12px rgba(0,0,0,0.05);
}

.user-marketing-header-right {
  flex: 1;
}

.user-marketing-section {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 0px 100px 0px;
}

.user-marketing-section-header {
  width: 50%;
}

.user-marketing-products {
  display: flex;
  align-items: center;
}

.user-marketing-product {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.user-marketing-product-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}

/* Requester Base */

.requester-threads-base {
  height: calc(100vh - 75px);
  flex: 1;
  display: flex;
}

/* Requester Threads */

.requester-thread-list {
  width: 400px;
  border-right: 1px solid #DADADA;  
  padding: 25px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.requester-thread-list-groups {
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow-y: scroll;
  max-height: calc(100vh - 116px);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.requester-thread-list-groups::-webkit-scrollbar {
  display: none;
}

.requester-thread-list-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.requester-thread-list-group-threads {
  display: flex;
  flex-direction: column;
}

.requester-thread-list-group-thread {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  transition: 100ms;
  cursor: pointer;
  border-bottom: 1px solid #dadada82;
  box-sizing: border-box;
  height: 70px;
}

.requester-thread-list-group-thread.selected {
  border-radius: 5px;
  background-color: #dbeedb;
}

.requester-thread-list-group-thread-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.requester-thread-list-group-thread-status.unread {
  background-color: #609C5E;
}

.requester-thread-list-group-thread-status.read {
  opacity: 0;
}

.requester-thread-list-group-thread-details {
  display: flex;
  flex-direction: column;
  gap: 7px;
  flex: 1;
}

.requester-thread-list-group-thread-header {
  display: flex;
  justify-content: space-between;
}

.requester-thread-list-group-thread-message {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis when there's overflow */
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to two lines */
  -webkit-box-orient: vertical;
}

.requester-thread-list-group-thread-new-message-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.requester-thread-list-group-thread-new-message {
  background-color: #609C5E;
  border-radius: 50px;
  padding: 4px 10px;
}

.requester-thread-viewer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-top: 15px;
}

.requester-threads-viewer-unselected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.requester-threads-viewer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DADADA;
  padding: 0px 0px 15px 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 20px;
}

.requester-threads-viewer-header-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.requester-threads-viewer-actions {
  padding: 15px 0px 15px 15px;
  overflow-y: scroll;
  max-height: calc(100vh - 257px);
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: absolute;
  top: 75px;
  left: 0px;
  right: 20px;
  bottom: 110px;
}

.requester-threads-viewer-actions::-webkit-scrollbar {
  display: none;
}

.requester-threads-viewer-action {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 0px
}

.requester-threads-viewer-action:first-child {
  padding-top: 0px;
}

.requester-threads-viewer-action:last-child {
  padding-bottom: 0px;
}

.requester-threads-viewer-action-request {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.requester-threads-viewer-action-response {
  display: flex;
  align-items: center;
  gap: 12px;
}

.requester-threads-viewer-action-response-bar {
  width: 4px;
  align-self: stretch;
  margin-left: 16px;
  border-radius: 20px;
  background-color: #D9D9D9;
}

.requester-threads-viewer-action-response-messages {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.requester-threads-viewer-message-level {
  width: 100%;
  margin: 5px 0px;
  display: flex;
}

.requester-threads-viewer-message-level.sent {
  justify-content: flex-end;
}

.requester-threads-viewer-message-level.received {
  justify-content: flex-start;
}

.requester-threads-viewer-message {
  display: inline-block;
  padding: 8px 14px;
  border-radius: 20px;
}

.requester-threads-viewer-message.sent {
  background-color: #609C5E;
}

.requester-threads-viewer-message.received {
  background-color: #ececec;
}

.requester-threads-viewer-composer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-top: 1px solid #DADADA;
  padding: 15px 0px 15px 15px;
  position: absolute;
  left: 0;
  right: 20px;
  bottom: 0;
}

.requester-threads-viewer-composer-modes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.requester-threads-viewer-composer-mode {
  background-color: #f6f6f6;
  border-radius: 50px;
  padding: 7px 14px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #a8a8a8
}

.requester-threads-viewer-composer-mode.selected {
  background-color: rgba(126, 153, 125, 0.2);
  border: 1px solid #609C5E;
  font-weight: 600;
  color: #609C5E;
}

.requester-threads-viewer-composer-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  background-color: #F3F3F3;
  border: 1px solid #DADADA;
  border-radius: 20px;
  padding: 7px 7px 7px 14px;
  width: 100%;
  box-sizing: border-box;
}

.requester-threads-viewer-composer-input {
  background-color: transparent;
  border: 0;
  height: 22px;
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  resize: none; /* Prevent users from resizing manually */
  overflow: hidden; /* Hide overflow content */
}

.requester-threads-viewer-composer-input:focus {
  outline: none;
}

.requester-threads-viewer-composer-send {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.requester-threads-viewer-composer-send-green-arrow {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #609C5E;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Characteristic List */

.characteristic-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.characteristic-list-item {
  background-color: #dbdbdb;
  border-radius: 50px;
  padding: 5px 10px;
}

/* Earnings Pill */

.earnings-pill {
  border: 1px solid #DADADA;
  border-radius: 50px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.08),0 4px 12px rgba(0,0,0,0.05);
  cursor: pointer;
  transition: box-shadow 0.1s;
}

.earnings-pill-content {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.earnings-pill:hover {
  box-shadow: 0 1px 20px rgba(0,0,0,0.08),0 4px 12px rgba(0,0,0,0.05);
}

/* Responder Thread List */

.responder-thread-list {
  width: 400px;
  border-right: 1px solid #DADADA;
  box-sizing: border-box;
  padding: 25px 20px 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
  max-height: calc(100vh - 75px);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.responder-thread-list::-webkit-scrollbar {
  display: none;
}

.responder-thread-list-thread {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 15px 15px;
  transition: 100ms;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 15px;
}

.responder-thread-list-thread.selected {
  background-color: #dbeedb;
}

.responder-thread-list-thread.unread {
  border: 2px solid #609C5E;
}

.responder-thread-list-thread.read {
  border: 2px solid #DADADA;
}

.responder-thread-list-thread-details {
  display: flex;
  justify-content: space-between;
  max-height: 33px;
}

.responder-thread-list-thread-type {
  border-radius: 50px;
  padding: 7px 12px;
  background-color: #b5b5b5;
  white-space: nowrap;
}

.responder-thread-list-thread-fare-and-estimate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.responder-threads-viewer-messages {
  padding: 15px 0px 15px 15px;
  overflow-y: scroll;
  max-height: calc(100vh - 228px);
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: absolute;
  top: 40px;
  left: 0px;
  right: 0px;
}

.responder-threads-viewer-messages::-webkit-scrollbar {
  display: none;
}

.responder-thread-list-thread-message {
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
