.order-button {
    border: 1px solid #DADADA;
    border-radius: 50px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0,0,0,0.08),0 4px 12px rgba(0,0,0,0.05);
    transition: box-shadow 0.1s;
    padding: 8px 10px 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    cursor: pointer;
}

.order-button:hover {
    box-shadow: 0 1px 20px rgba(0,0,0,0.08),0 4px 12px rgba(0,0,0,0.05);
}

.order-button-inputs {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 4px;
}

.order-button-input {
    white-space: nowrap;
    cursor: pointer;
    background-color: #FFFFFF;
    transition: 100ms;
    padding: 10px 12px 10px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.order-button-divider {
    width: 1px;
    background-color: #DADADA;
    height: 24px;
}

.order-button-submit {
    background-color: #609C5E;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}