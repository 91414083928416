.phone-border {
    position: absolute;
    z-index: 2;
    background-color: #F6F9FC;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 302px;
    height: 600px;
    box-shadow: 0 50px 100px -20px rgba(50,50,93,0.25), 0 30px 60px -30px rgba(0,0,0,0.3), inset 0 -2px 6px 0 rgba(10,37,64,0.35);
    margin-top: 60px;
}

.phone-base {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px 0px 16px 0px;
}

.phone-navbar-message-groups {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.phone-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 16px 10px;
    border-bottom: 1px solid #EDEDED;
}

.phone-name-text {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #000000;
}

.phone-message-groups {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 10px;
}

.phone-message-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.phone-request-message {
    display: inline-block;
    padding: 8px 14px;
    border-radius: 16px;
    background-color: #609C5E;
    max-width: 80%;
}

.phone-request-message-text {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    color: #FFFFFF;
    line-height: 1.5;
}

.phone-response-messages {
    display: flex;
    gap: 10px;
    padding-left: 12px;
}

.phone-response-bar {
    border-radius: 20px;
    background-color: #DADADA;
    width: 4px;
    height: 100%;
}

.phone-response-message {
    display: inline-block;
    padding: 8px 14px;
    border-radius: 16px;
    background-color: #ECECEC;
    max-width: 80%;
}

.phone-response-message-text {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    color: #000000;
    line-height: 1.5;
}

.phone-text-box {
    border: 1px solid #DADADA;
    border-radius: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 12px;
    margin: 0px 10px 0px 10px;
}

.phone-text-box-text {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    color: #DADADA;
}