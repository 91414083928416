.blog-post-section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    text-align: left;
    padding: 100px 0px;
}

.blog-post-ancestry {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
}

.blog-post-ancestry-button-text {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #609C5E;
    transition: 100ms;
}

.blog-post-ancestry-button-text:hover {
    filter: brightness(0.85);
}

.blog-post-container {
    width: 60%;
    margin: 0 auto;
    padding-top: 30px;
}

.blog-post-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 60px;
}

.blog-post-title-text {
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    color: #000000;
}

.blog-post-author-date {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.blog-post-author-name {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #000000;
}

.blog-post-author-title {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #727272;
}

.blog-post-date-text {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    color: #000000;
}

.blog-post-content-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog-post-text {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    color: #000000;
    line-height: 26px;
    letter-spacing: 0.2px;
}

.blog-post-header-text {
    font-size: 26px;
    font-weight: 700;
    color: #000000;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-top: 40px;
    margin-bottom: -10px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}