.desktop-persona-selection-viewer {
    position: absolute;
    top: 0px;
    left: 253px;
    background: linear-gradient(hsla(0, 0%, 100%, 0.4), hsla(0, 0%, 100%, 0.3) 25%, rgba(246, 249, 252, 0.3) 50%);
    width: 800px;
    padding: 24px;
    border-radius: 8px;
    box-shadow: inset 0 1px 1px 0 hsla(0,0%,100%,.1),0 50px 100px -20px rgba(50,50,93,.25),0 30px 60px -30px rgba(0,0,0,.3);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.desktop-persona-selection-viewer-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.desktop-persona-selection-viewer-navbar-logo {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 5px;
    padding-bottom: 0px;
}

.desktop-persona-selection-viewer-navbar-logo-image {
    width: 30px;
    height: 30px;
}

.desktop-persona-selection-viewer-persona-button {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 50px;
    padding: 7px 12px 7px 20px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.08),0 4px 12px rgba(0,0,0,0.05);
    cursor: pointer;
    transition: box-shadow 0.1s;
}
  
.desktop-persona-selection-viewer-persona-button:hover {
    box-shadow: 0 1px 20px rgba(0,0,0,0.08),0 4px 12px rgba(0,0,0,0.05);
}

.desktop-persona-selection-viewer-persona-text-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.desktop-persona-selection-viewer-persona-text {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #000000;
}

.desktop-persona-selection-viewer-persona-text-section-divider {
    height: 25px;
    width: 1px;
    background-color: #DADADA;
}

.desktop-persona-selection-viewer-persona-submit-button {
    background-color: #609C5E;
    border-radius: 100px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktop-persona-selection-viewer-navbar-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.desktop-persona-selection-viewer-main-content {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.desktop-persona-selection-viewer-persona-filter-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.desktop-persona-selection-viewer-persona-filters {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.desktop-persona-selection-viewer-persona-filter {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 50px;
    padding: 6px 14px;
}

.desktop-persona-selection-viewer-persona-filter-text {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    color: #000000;
}

.desktop-persona-selection-viewer-persona-filter-arrows {
    display: flex;
    align-items: center;
    gap: 5px;
}

.desktop-persona-selection-viewer-persona-filter-arrow {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 50px;
    padding: 6px;
}

.desktop-persona-selection-viewer-thread-view {
    display: flex;
    align-items: flex-start;
}

.desktop-persona-selection-viewer-thread-group-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-right: 15px;
    border-right: 1px solid #DADADA;
}

.desktop-persona-selection-viewer-thread-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.desktop-persona-selection-viewer-thread-group-traits-list {
    display: flex;
    align-items: center;
    gap: 5px;
}

.desktop-persona-selection-viewer-thread-group-trait {
    background-color: #DADADA;
    padding: 5px 10px;
    border-radius: 50px;
    white-space: nowrap;
}

.desktop-persona-selection-viewer-thread-group-trait-text {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    color: #000000;
}

.desktop-persona-selection-viewer-thread-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.desktop-persona-selection-viewer-thread {
    display: flex;
    align-items: center;
    gap: 5px;
}

.desktop-persona-selection-viewer-thread-unread-status {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #609C5E;
}

.desktop-persona-selection-viewer-thread-data {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.desktop-persona-selection-viewer-thread-responder-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.desktop-persona-selection-viewer-thread-responder-text {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    color: #000000;
}

.desktop-persona-selection-viewer-thread-time {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: gray;
}

.desktop-persona-selection-viewer-thread-preview-text {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #000000;
}

.desktop-persona-selection-viewer-thread-detail-view {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.desktop-persona-selection-viewer-thread-detail-view-responder-metadata {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #DADADA;
    padding-bottom: 10px;
    padding-left: 15px;
}

.desktop-persona-selection-viewer-thread-detail-view-responder-text {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    color: #000000;
}